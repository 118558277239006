import React from "react";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const MSPIForm = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Application Form - MSPI" />
        <iframe
              src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUOUJJWklCNkpXNk04UkFOTkFIWjcxNTJYNi4u&embed=true"
              frameBorder="0"
              marginWidth="0"
              marginHeight="0"
              style={{
                width: "100%",
                height: "2000px",
                border: "none",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              allowFullScreen
            />
        <div className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl">
          <div className="px-1 pb-5 ">
           
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MSPIForm;

